* {
  padding: 0;
  box-sizing: border-box;

  font-family: 'Poppins Regular';
}
body {
  color: white;
  background-color: #202024;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'Poppins Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf');
}
.congratulation {
  color: white;
  font-size: 1.2rem;
}
.profile-photo {
  align-items: center;
  justify-content: center;
  margin-top: 2rem !important;
}
.profile-photo p {
  font-size: 1rem;
  margin: 10px;
  color: white;
}
.profile-photo p:nth-child(2) {
  font-size: 0.8rem;
  margin: 10px;
  color: white;
}
.video-container {
  overflow: hidden;
  position: relative;
  width: 75%;
}
.email {
  cursor: pointer;
}
.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container-curso {
  overflow: hidden;
  position: relative;
  width: 80% !important;
}

.video-container-curso::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}
input {
  color: #202024 !important;
}
.video-container-curso iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videos-curso {
  background-color: #202024;
}
.aula {
  margin-bottom: 3rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #000;
  margin: auto;
  width: 70%;
  margin-bottom: 3rem;
  border-radius: 20px;
  margin-top: 3rem;
}
.conteudos {
  margin-bottom: 3rem;

  margin: auto;
  width: 70%;
  margin-bottom: 3rem;
  margin-top: 1rem;
}
.div-curso {
  margin-right: 0;
}
.cadeado {
  align-self: center;
  align-items: baseline;
  vertical-align: bottom;
}
.cadeado h2 {
  margin-right: 1rem;
}
.aula {
  cursor: pointer;
}
.aula-2 {
  cursor: not-allowed;
}
