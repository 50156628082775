.form-card {
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  padding: 4rem 2rem;
}
input {
  border: 1px solid #1d1d1d;
  border-radius: 5px;
  margin: 0.5rem 0 !important;
  padding: 0.3rem 0.5rem;
  background-color: white;
  width: 100%;
}
form {
  background-color: white;
  justify-content: center;
}
button {
  background-color: #621785;
  border: none;
  border-radius: 8px;
  color: #fff;
  /* margin-top: 1rem !important; */
  padding: 0.5rem;
  text-align: center;
  width: 100%;
}
.button {
  background-color: white;
}
h3 {
  color: #1d1d1d;
}
.success {
  color: #621785;
  font-weight: 700;
}
