* {
  color: white;
  font-family: 'Poppins Regular';
}
body,
html,
#root {
  background-color: #161616;
}
h2 {
  font-family: 'Poppins Bold';
}
#timer p {
  margin: 0rem !important;
  color: white;
}
#timer div {
  text-align: center;
  margin-right: 1rem;
}
#timer {
  position: fixed;
  display: flex;
  justify-content: center;
  margin: auto;
  height: 5vh;
  background-color: #ee6e0c;
  width: -webkit-fill-available;
  z-index: 99999;
}
.capitulos {
  display: flex;
  flex-direction: column;
}
.button {
  padding: 1rem;
}
.mr-3 {
  margin-right: 1rem;
}
.card-questions {
  width: 100% !important;
  height: 4rem;
  border-radius: 10px;
  background-color: #2b2b2b !important;
  display: flex;
  border-color: #2b2b2b !important;
  text-align: left !important;
  align-items: center;
  padding: 0 2rem !important;
}
.width-100 {
  width: 100%;
}
.accordion {
  width: 100%;
}
button {
  text-decoration: none !important;
  color: white !important;
}
button:hover {
  text-decoration: none !important;
}
.span-font {
  font-size: 0.7rem;
}
.color-blue-text {
  color: #4285f4;
  font-family: 'Poppins Bold';
}
.pr-5 {
  padding-right: 3rem;
}
.pr-2 {
  padding-right: 1rem;
}
.numbers-capitulo {
  font-family: 'Poppins Bold';
}
.video-teaser{
  height: 80vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.video-container {
  overflow: hidden;
  position: relative;
  width: 75%;
}
.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}
.video-container-teaser {
  overflow: hidden;
  position: relative;
  padding: 0;
  width: 101%;
}

.text-left {
  text-align: left;
}
.mr-4 {
  margin-right: 2rem;
}
.preco-bonus {
  margin-left: 5rem;
}
.preco-bonus p,
.preco-bonus h5 {
  margin: 0;
  padding: 0;
}
.preco-bonus p {
  color: #afafaf;
}
.preco-bonus strike {
  color: red;
  display: flex;
}
.preco-bonus span {
  color: white;
  font-size: 2rem;
}

.subtitle {
  color: #afafaf;
  /* margin-bottom: 2rem; */
}
.span-font {
  margin-bottom: 2rem;
}

.navbar {
  padding-top: 1rem;
  /* position: fixed;
  margin: auto;
  width: 100%;
  max-width: 2300px; */
}
.timer-promocao {
  align-items: center;
  align-content: center;
  display: flex;
}
.cupom {
  padding-right: 2rem;
}
.cupom strong {
  border: 1px dotted white;
  padding: 3px 8px;
}
/* .navbar{
  position: fixed;
  z-index: 999;
  background-color: #222222 !important;
  width: 100%;
} */
.subtitle-footer {
  color: #afafaf;
  margin-bottom: 2rem;
  font-size: 0.7rem !important;
}
.third-section {
  background-color: #222222;
}
.accordion {
  background-color: #222222 !important;
}
.accordion-button {
  background-color: #161616 !important;
}
.accordion-body {
  background-color: #222222;
}
.accordion-button {
  margin: 0;
  border: none;
  border-radius: 5px;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.accordion-item {
  border: none !important;
  margin: 1rem 1rem;
  border-radius: 5px;
  background-color: #222222 !important;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button-first-section {
  background-color: #00ab66 !important;
  width: 30%;
  margin: auto;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  box-shadow: 2px 4px 11px #242424b0;
  font-size: 1.1rem;
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
}
.text-capitulo {
  color: white;
}
.text-lp {
  color: white !important;
  font-size: 1rem;
}
.first-text {
  font-family: 'Poppins Regular';
}
strong {
  font-family: 'Poppins Bold';
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 75%) !important;
}
#modal_confirm_action {
  inset: 5rem;
  margin: auto;
  max-height: fit-content;
  max-width: 800px !important;
}
a {
  text-decoration: none !important;
}
.valor-bonus {
  color: white;
  font-weight: bold;
}
.valor-bonus h3,
.valor-bonus p {
  color: white;
  font-weight: bold;
}
.condicoes {
  color: #afafaf !important;
  font-size: 0.8rem;
}
.bonus-value {
  font-size: 2rem;
}
.subtitle-bonus {
  color: #afafaf !important;
}
.navbar-options {
  color: white;
}
.navbar-options:hover {
  color: rgb(216, 144, 245);
  font-weight: bold;
}
.navbar-toggler {
  width: 20% !important;
  margin-top: 0 !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.video-teaser {
  display: none;
}
@media screen and (max-width: 900px) {
  #timer {
    height: auto;
  }
  .video-teaser-horizontal {
    display: none;
  }
  .video-teaser {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mockup-bonus {
    display: none;
  }
  .navbar-collapse {
    text-align: right;
  }
  .navbar-toggler {
    border: none !important;
  }
  .container-fluid {
    align-items: baseline;
  }
  .navbar {
    padding-top: 1rem;
  }
  .timer-promocao p {
    padding-top: 0.5rem;
    font-size: 1rem;
  }
  .timer-promocao {
    flex-direction: column;
  }
  .cupom {
    padding-right: 0rem;
  }
  .cupom p {
    font-size: 1rem;
  }
  .bonus {
    margin-left: 1rem;
  }
  #modal_confirm_action {
    inset: 40px !important;
    margin: auto;
    max-height: fit-content;
  }

  .card-noticias {
    margin-bottom: 3rem !important;
  }
  .card-noticias .subtitle {
    margin-bottom: 0rem !important;
  }
  .preco-bonus {
    margin-left: 1rem !important;
  }
  .button-first-section {
    width: 100%;
  }
  p {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1.7rem !important;
  }
  /* h5 {
    font-family: 'Poppins Bold';
  } */
  .subtitle.pr-5 {
    padding-right: 0px;
  }
  .footer > p {
    font-size: 0.5rem;
  }
  .garantia {
    margin-top: 4rem;
  }
  .img-price {
    width: 100%;
  }
  .footer-direitos {
    margin-right: 1rem;
  }
}
.faq-questions {
  color: white;
  font-size: 1rem;
}
.img-price {
  padding: 1rem;
}
.navbar,
.image-transparent-background {
  background-color: transparent;
}

.first-section {
  background-image: url('../../assets/images/background-purple.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: max-content;
}

.video-react-button.video-react-big-play-button.video-react-big-play-button-left {
  display: none;
}
.video-react {
  margin: auto;
}
